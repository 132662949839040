import React from "react";

const PrivacyPolicy = () => (
  <section>
    <div className="container py-16">
      {/* <h1 className="font-bold text-6xl mb-[100px]">PRIVACY POLICY</h1> */}
      <h2 className="font-bold mb-3 text-[30px]">PRIVACY POLICY</h2>
      <p className="mb-6">
        In order not to damage the relationship of trust between Digital Research Corporation
        (“DRC”) and the customer, we strive to protect the deposited personal information of the
        customer appropriately by establishing the following policy.
      </p>
      <h2 className="font-bold mb-3 text-[30px]">ACQUISITION OF PERSONAL INFORMATION</h2>
      <p className="mb-6">
        DRC will acquire the customer’s personal information by lawful and fair means that comply
        with laws and regulations.
      </p>
      <h2 className="font-bold mb-3 text-[30px]">HANDLING OF PERSONAL INFORMATION</h2>
      <p className="mb-6">
        DRC shall comply with laws and other regulations related to handling of personal information
        in order to handle personal information properly. DRC will clarify the regulations related
        to the handling of personal information and thoroughly inform employees. DRC will also
        request proper handling to outsourcing parties handling personal information.
      </p>
      <h2 className="font-bold mb-3 text-[30px]">PURPOSE OF USE OF PERSONAL INFORMATION</h2>
      <p className="mb-4">
        In order to provide the customer with more high-quality services and publications, DRC may
        request the customer personal information such as name, e-mail address, occupation, place of
        work, job title, date of birth, sex, address, telephone number, etc. The personal
        information provided by the customer will be used solely for the following purposes. If
        there is a necessity to use any personal information provided by the customer beyond the
        scope of the following purposes, we will notify the customer and make necessary procedures;
      </p>
      <ol className="list-decimal list-inside pl-6 mb-6">
        <li>
          To provide our information distribution service to the customer who has registered for
          DRC’s service.
        </li>
        <li>To deal with customer’s inquiries or requests.</li>
        <li>
          To provide and/or suggest information on the products and services provided by DRC by
          means such as direct marketing and telemarketing etc.
        </li>
        <li>
          To introduce magazines, books, seminars, and events, etc. and conduct questionnaire
          surveys etc.
        </li>
        <li>
          To provide information to advertisers of the ad placed on DRC’s media or the seller of the
          product introduced on DRC’s media according to the “Use of Personal Information and
          Disclosure / Provision to Third Parties” shown below.
        </li>
        <li>
          To analyze the use of services by the customer, develop new services and improve existing
          services.
        </li>
        <li>
          To personalize content and advertisements based on attributes such as occupation, type of
          business, age, title, etc. or browsing history on DRC’s media.
        </li>
        <li>
          To facilitate communication between customers and companies and organizations that are
          providers of IT-related products and services included in the information posted on our
          media.
        </li>
      </ol>
      <h2 className="font-bold mb-3 text-[30px]">
        MANAGEMENT AND PROTECTION OF PERSONAL INFORMATION
      </h2>
      <p className="mb-6">
        In order to provide reasonable safety measures against risks such as unauthorized access to
        personal information, leakage, loss, damage, etc. of personal information, DRC will
        consistently devote any management resources that is in accordance with the actual situation
        of the business and continually upgrade and/or improve the security system of personal
        information. Also, in the event of an accident such as leakage of personal information, DRC
        will take action quickly and correspondingly.
      </p>
      <h2 className="font-bold mb-3 text-[30px]">
        USE OF PERSONAL INFORMATION AND DISCLOSURE / PROVISION TO THIRD PARTIES
      </h2>
      <p className="mb-4">
        DRC will not use the customer’s personal information for purposes other than those mentioned
        above unless the customer agrees. In addition, DRC will not disclose/provide personal
        information to third parties without customer’s consent, except in the following cases and
        clearly specified in this Privacy Policy;
      </p>
      <ul className="mb-4 list-disc list-inside pl-6">
        <li>Based on the provisions of laws and regulations.</li>
        <li>
          In the case of providing to advertisers etc. based on customer’s request for information,
          inquiries, etc.
        </li>
      </ul>
      <p className="mb-4">
        In the following cases, DRC will provide personal information to the advertiser for which
        the customer requested documents or the seller of the product that the customer has viewed
        the details. The personal information provided in this way will be managed by those
        advertisers or sellers. For details of the handling of personal information in this case,
        please contact the advertiser or seller accordingly;
      </p>
      <ul className="mb-4 list-disc list-inside pl-6">
        <li>
          When the customer has requested documents or inquired about the advertisement appeared in
          DRC’s media.
        </li>
        <li>When the customer viewed details of the advertisement/advertiser on DRC’s media.</li>
        <li>When the customer viewed details of products appeared in DRC’s media.</li>
      </ul>
      <p className="mb-6">
        The range of the personal information provided to the advertiser, which is included in
        “Purpose of Use of Personal Information” column above, is the name, e-mail address,
        occupation, place of work, position, address and telephone number. Means and methods for
        providing to advertisers are through the system which DRC controls, electronic media such as
        CD-ROM and analog media such as paper. If you wish to suspend the offer to the advertiser,
        please follow the procedure described in the column “Inquiries, Corrections, Deletions of
        Information and Suspension of Information Provision to Third Parties” below.
      </p>
      <h2 className="font-bold mb-3 text-[30px]">SHARED USE OF PERSONAL INFORMATION</h2>
      <p className="mb-6">
        DRC may share personal information of the customer within the scope necessary for achieving
        the purpose of use with DRC’s group companies such as companies and affiliates in
        parent-subsidiary relationship with DRC. The scope and the purpose of use of personal
        information to be shared are the same as described in the “Purpose of Use of Personal
        Information” article above. In addition, the person responsible for administration in the
        shared use shall be DRC. For specific inquiries, please contact here.
      </p>
      <h2 className="font-bold mb-3 text-[30px]">
        INQUIRIES, CORRECTIONS, DELETIONS OF INFORMATION AND SUSPENSION OF INFORMATION PROVISION TO
        THIRD PARTIES
      </h2>
      <p className="mb-6">
        The customer can contact DRC regarding disclosure of personal information about him/herself
        (notification/disclosure of purpose of use, correction/addition/deletion of contents of
        personal information, suspension or elimination of use of personal information, and
        suspension of information provision to third parties such as advertisers) at DRC’s contact
        specified below. In such cases, DRC will respond within a reasonable period upon identity
        confirmation of the customer. Please also contact us regarding complaints and consultations
        concerning DRC’s handling of personal information at the contact information below. In
        addition, in the case where the customer has stopped or eliminated the use of personal
        information or conducted the procedure to suspend the provision of advertisers etc. to a
        third party, the customer may not be able to use all or part of the services provided by
        DRC. In addition, in the case where the customer has stopped or eliminated the use of
        personal information or conducted the procedure to suspend the provision of advertisers etc.
        to a third party, the customer may not be able to use all or part of the services provided
        by DRC.
      </p>
      <h2 className="font-bold mb-3 text-[30px]">SHARED USE OF PERSONAL INFORMATION</h2>
      <p className="mb-6">
        DRC may share personal information of the customer within the scope necessary for achieving
        the purpose of use with DRC’s group companies such as companies and affiliates in
        parent-subsidiary relationship with DRC. The scope and the purpose of use of personal
        information to be shared are the same as described in the “Purpose of Use of Personal
        Information” article above. In addition, the person responsible for administration in the
        shared use shall be DRC. For specific inquiries, please contact here.
      </p>
      <h2 className="font-bold mb-3 text-[30px]">OUTSOURCING OF INFORMATION PROCESSING</h2>
      <p className="mb-4">
        When consigning handling of personal information, DRC assigns outsourcers who are compliant
        with DRC’s expected protection level of personal information and conduct necessary and
        appropriate supervision of outsourcing partners so that safety management can be planned.
        ABOUT BEHAVIORAL TARGETING ADS DRC provides “behavioral targeting advertisements” that
        deliver advertisements that are considered to be more useful according to the interests and
        interests of our customer.
      </p>
      <ul className="mb-4 list-disc list-inside pl-6">
        <li>
          <h3 className="inline-block mb-3 font-bold">INFORMATION USED</h3>
          <p className="mb-4">
            In order to provide behavioral targeting advertisements, DRC uses “cookies” to obtain
            and use information on websites that have been browsed by the customer, date and time
            browsed, information on products or services displayed or searched, etc., uses
            information such as gender and age entered by the customer when registering, but DRC do
            not use information that can identify any specific individual. The maximum period of use
            of information used to provide behavioral targeting advertisements is “1 year”.
          </p>
        </li>
        <li>
          <h3 className="inline-block mb-3 font-bold">DISABLING BEHAVIORAL TARGETING ADS</h3>
          <p className="mb-4">
            Customer who does not wish to receive behavioral targeting advertisement can set the
            display to be disabled by the following methods. Even after being disabled, regular ADs
            that are not based on behavioral targeting will be displayed.
          </p>
          <h3 className="font-bold mb-3">DISABLING METHOD</h3>
          <p className="mb-6">
            If the customer wishes to stop such advertisement distribution, the settings can be
            changed by the customer on the opt-out pages below;
            <br />
            <a
              href="https://policies.google.com/technologies/ads?gl=jp"
              rel="noreferer"
              target="_blank"
              className="underline"
            >
              Google opt-out page
            </a>
            <br />
            <a
              href="https://btoptout.yahoo.co.jp/optout/index.html
btoptout.yahoo.co.jp"
              rel="noreferer"
              target="_blank"
              className="underline"
            >
              Yahoo! opt-out page
            </a>
            <br />
            <a
              href="https://www.facebook.com/adpreferences/ad_settings/?entry_product=account_settings_menu"
              rel="noreferer"
              target="_blank"
              className="underline"
            >
              Facebook opt-out page
            </a>
            <br />
          </p>
        </li>
      </ul>
      <h2 className="font-bold mb-3 text-[30px]">CONTACT</h2>
      <p className="mb-6">
        Personal Information
        <br />
        privacy@digitalresearch.net
        <br />
        #609 Miyamasuzaka Bldg., 2-19-15 Shibuya, Shibuya-ku, Tokyo 150-0002, Japan
      </p>
    </div>
  </section>
);

export default PrivacyPolicy;
